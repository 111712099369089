<template>
    <div>
      <b-modal id="modal-calendar-edit" size="lg" :title="$t('calendar.title.edit_calendar_event')" footer-class="justify-content-between">
        <ValidationObserver ref="form_calendar">
            <div class="d-flex">
                <div class="col-6">
                  <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                      <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                          <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                  </span>
                      {{ $t('calendar.title.event_name') }}
                  </h3> 
                  <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                    <input 
                          type="text" 
                          class="form-control form-control-lg form-control-solid"
                          :placeholder="$t('calendar.placeholder.event_name')"
                          v-model="event.title"
                    >                
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-6">
                  <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                      <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                          <inline-svg src="/media/svg/icons/Home/Building.svg" />
                  </span>
                      {{ $t('calendar.title.event_address') }}
                  </h3> 
                  <input 
                          type="text" 
                          class="form-control form-control-lg form-control-solid"
                          :placeholder="$t('calendar.placeholder.event_address')"
                          v-model="event.address"
                    >   
                </div>
            </div>

              <b-tabs card class="mt-4" active-nav-item-class="bg-primary text-white">
                <b-tab :title="$t('calendar.title.tab_main_info')">
                  <b-card-text>
                    <div class="row d-flex align-items-center">
                      <div class="col-4">

                          <b-form-checkbox  name="check-button" switch size="l" class="d-inline" value=1 unchecked-value=0>{{ $t('calendar.title.allday_event') }}</b-form-checkbox>     
                          
                          <strong class="d-block mt-4 mb-2">{{ $t('calendar.title.date_start') }}</strong>
                          <date-picker v-model="event.start" class="mb-2" type="datetime" format="DD.MM.YYYY HH:mm" value-type="YYYY-MM-DD HH:mm"></date-picker>

                          <strong class="d-block mb-2">{{ $t('calendar.title.date_end') }}</strong>
                          <date-picker v-model="event.end" type="datetime" format="DD.MM.YYYY HH:mm" value-type="YYYY-MM-DD HH:mm"></date-picker>
                      </div>
                      <div class="col-8 align-items-center justify-content-center border-left text-center py-5 mt-8 even-type-list">
                          
                        <b-form-group>
                              <b-form-radio-group class="pt-4">
                                  <b-form-radio v-model="event.type" value="meeting">
                                    <span class="symbol symbol-50 symbol-light-primary mr-6">
                                      <span class="label label-md font-weight-bold label-rounded label-success" style="position: absolute; right: -5px; top: -5px;" v-if="event.type == 'meeting'"><i class="fa fa-check text-white icon-sm"></i></span>
                                      <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                                          <inline-svg src="/media/svg/icons/Clothes/Briefcase.svg" />
                                        </span>
                                      </span>
                                      <span class="font-size-xs text-primary mt-1 text-center d-block">{{ $t('calendar.title.event_type_meeting') }}</span>
                                    </span>
                                  </b-form-radio>

                                  <b-form-radio v-model="event.type" value="call">
                                    <span class="symbol symbol-50 symbol-light-primary mr-6">
                                      <span class="label label-md font-weight-bold label-rounded label-success" style="position: absolute; right: -5px; top: -5px;" v-if="event.type == 'call'"><i class="fa fa-check text-white icon-sm"></i></span>
                                      <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                                          <inline-svg src="/media/svg/icons/Communication/Outgoing-call.svg" />
                                        </span>
                                      </span>
                                      <span class="font-size-xs text-primary mt-1 text-center d-block">{{ $t('calendar.title.event_type_call') }}</span>
                                    </span>
                                  </b-form-radio>

                                  <b-form-radio v-model="event.type" value="email">
                                    <span class="symbol symbol-50 symbol-light-primary mr-6">
                                      <span class="label label-md font-weight-bold label-rounded label-success" style="position: absolute; right: -5px; top: -5px;" v-if="event.type == 'email'"><i class="fa fa-check text-white icon-sm"></i></span>
                                      <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                                          <inline-svg src="/media/svg/icons/Communication/Outgoing-mail.svg" />
                                        </span>
                                      </span>
                                      <span class="font-size-xs text-primary mt-1 text-center d-block">{{ $t('calendar.title.event_type_email') }}</span>
                                    </span>
                                  </b-form-radio>

                                  <b-form-radio v-model="event.type" value="holiday">
                                    <span class="symbol symbol-50 symbol-light-primary mr-6">
                                      <span class="label label-md font-weight-bold label-rounded label-success" style="position: absolute; right: -5px; top: -5px;" v-if="event.type == 'holiday'"><i class="fa fa-check text-white icon-sm"></i></span>
                                      <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                                          <inline-svg src="/media/svg/icons/Food/Coffee2.svg" />
                                        </span>
                                      </span>
                                      <span class="font-size-xs text-primary mt-1 text-center d-block">{{ $t('calendar.title.event_type_holiday') }}</span>
                                    </span>
                                  </b-form-radio>

                                  <b-form-radio v-model="event.type" value="other">
                                    <div class="symbol symbol-50 symbol-light-primary mr-6">
                                      <span class="label label-md font-weight-bold label-rounded label-success" style="position: absolute; right: -5px; top: -5px;" v-if="event.type == 'other'"><i class="fa fa-check text-white icon-sm"></i></span>
                                      <span class="symbol-label">
                                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                                          <inline-svg src="/media/svg/icons/Shopping/Box1.svg" />
                                        </span>
                                      </span>
                                      <span class="font-size-xs text-primary mt-1 text-center d-block">{{ $t('calendar.title.event_type_other') }}</span>
                                    </div>
                                  </b-form-radio>

                                </b-form-radio-group>
                            </b-form-group>
                      </div>
                    </div>

                    <strong class="mt-7 mb-2 d-block">{{ $t('calendar.title.note') }}</strong>
                    <ckeditor :editor="editor" v-model="event.description" :config="editorConfig"></ckeditor>
                  </b-card-text>
                </b-tab>

                <b-tab :title="$t('calendar.title.tab_peoples')">
                  <b-card-text>
                      Tu budou pozvaní uživatelé.
                  </b-card-text>
                </b-tab>
              </b-tabs>
          
          </ValidationObserver>

          <template #modal-footer="{ cancel }">
              <div>
                  <b-button variant="danger"  ref="delete_event" @click="deleteEvent(event.event_id)" v-if="event.id">
                      {{ $t('calendar.button.delete_event') }}
                  </b-button>   
              </div> 
              <div>
                <b-button variant="secondary" @click="cancel()" class="mr-3">
                  {{ $t('button.cancel') }}
                </b-button> 
                <b-button variant="success" @click="submitEvent" ref="save_submit">
                  {{ $t('calendar.button.save_event') }}
                </b-button>   
              </div>      
          </template>
      </b-modal>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  name: "calendar_modal",
  data() {
      return {
        editor: ClassicEditor,
        editorConfig: {
              // The configuration of the editor.
        },
        endpoint: null
      };
  },
  props: {
    event: {
          id: null,
          title: null,
          address: null,
          type: null,
          start: null,
          end: null,      
          description: null,
          addDay: null
    },
  },
  methods: {
    submitEvent(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
        if(this.event.id){
          this.endpoint = "/calendar-events/" + this.event.id + "/update"
        } else {
          this.endpoint  = "/calendar-events";
        }
        ApiService.apiPost(this.endpoint , 
        { 
          'title': this.event.title,
          'type': this.event.type,
          'address': this.event.address,
          'start': this.event.start,
          'end': this.event.end,
          'all_day': this.event.allDay,
          'background': this.backgroundSwitch,
          'description': this.event.description
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            this.$bvModal.hide('modal-calendar-edit');
            this.$parent.getEvents();

        }).catch(error => {
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            checkErrors(error);
       });
    },
  },
  computed: {
    backgroundSwitch: function () {
        var eventColor = '';  
        switch(this.event.type)
        {
            case 'meeting': eventColor = "#4e86d2"; break;
            case 'call': eventColor = "#4ed283"; break;
            case 'email': eventColor = "#d26d4e"; break;
            case 'holiday': eventColor = "#7d4ed2"; break;
            case 'other': eventColor = "#7d4ed2"; break;
        }
        return eventColor;
    }
  }
};
</script>
