<template>
  <div>
    <div class="d-flex flex-row calendar">
      
      <div class="flex-row-fluid mr-lg-8">
        <div class="card card-custom">
            <div class="card-body">
                <FullCalendar :options="calendarOptions" />
            </div>
        </div>

      </div>
      <div class="flex-row-auto offcanvas-mobile w-250px w-xl-250px calendar-menu">
        <div class="card card-custom gutter-b">
          <div class="card-body px-3 py-4">
                <div class="navi navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon">
              
                    <span class="btn btn-success w-100" v-b-modal.modal-calendar-edit @click.prevent="setNewEvent">
                      <span class="svg-icon svg-icon-md svg-icon-white">
                        <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                      </span>
                      {{ $t("calendar.button.create_event") }}
                    </span>

                    <b-form-checkbox  value="meeting" v-model="type"> 
                      <div class="navi-item">
                          <span class="navi-link cursor-pointer">
                                <span class="navi-icon ml-8 mr-4">
                                    <span class="svg-icon svg-icon-lg">
                                        <inline-svg src="/media/svg/icons/Clothes/Briefcase.svg" />
                                    </span>
                                </span>
                                <span class="navi-text font-weight-bolder font-size-lg">{{ $t("calendar.title.event_type_meeting") }}</span>
                                <i class="fa fa-square legend-meeting"></i>
                          </span>
                      </div>
                    </b-form-checkbox>
           
                    <b-form-checkbox  value="call" v-model="type"> 
                      <div class="navi-item">
                          <span class="navi-link cursor-pointer">
                              <span class="navi-icon ml-8 mr-4">
                                  <span class="svg-icon svg-icon-lg">
                                      <inline-svg src="/media/svg/icons/Communication/Outgoing-call.svg" />
                                  </span>
                              </span>
                              <span class="navi-text font-weight-bolder font-size-lg">{{ $t("calendar.title.event_type_call") }}</span>
                              <i class="fa fa-square legend-call"></i>
                         </span>
                      </div>
                    </b-form-checkbox>
            
                    <b-form-checkbox  value="email" v-model="type"> 
                      <div class="navi-item">
                          <span class="navi-link cursor-pointer">
                              <span class="navi-icon ml-8 mr-4">
                                  <span class="svg-icon svg-icon-lg">
                                    <inline-svg src="/media/svg/icons/Communication/Outgoing-mail.svg" />
                                  </span>
                              </span>
                              <span class="navi-text font-weight-bolder font-size-lg">{{ $t("calendar.title.event_type_email") }}</span>
                              <i class="fa fa-square legend-email"></i>
                          </span>
                      </div>
                    </b-form-checkbox>
             
                    <b-form-checkbox  value="holiday" v-model="type"> 
                      <div class="navi-item">
                          <span class="navi-link cursor-pointer">
                              <span class="navi-icon ml-8 mr-4">
                                  <inline-svg src="/media/svg/icons/Food/Coffee2.svg" />
                              </span>
                              <span class="navi-text font-weight-bolder font-size-lg">{{ $t("calendar.title.event_type_holiday") }}</span>
                              <i class="fa fa-square legend-holiday"></i>
                          </span>
                      </div>
                    </b-form-checkbox>
                
                    <b-form-checkbox  value="other" v-model="type"> 
                      <div class="navi-item">
                          <span class="navi-link cursor-pointer">
                              <span class="navi-icon ml-8 mr-4">
                                  <inline-svg src="/media/svg/icons/Shopping/Box1.svg" />
                              </span>
                              <span class="navi-text font-weight-bolder font-size-lg">{{ $t("calendar.title.event_type_other") }}</span>
                              <i class="fa fa-square legend-other"></i>
                          </span>
                      </div>
                    </b-form-checkbox>
                    
                    <b-button variant="primary" class="d-block w-100 mt-3" @click="getEvents">
                        {{ $t('calendar.button.filter') }}
                    </b-button> 
                </div>
            </div>
        </div>

        <div class="card card-custom">
            <div class="card-body px-3 py-4">
                <strong class="d-block mb-2 ml-2">{{ $t('calendar.title.legend') }}:</strong>
                <div class="navi">
                  <div class="navi-item">
                        <span class="navi-link">
                            <span class="navi-text">{{ $t('calendar.title.today_date') }}</span>
                            <span class="mr-0">
                                <span class="py-1 px-2 legend-today">{{currentDay}}</span>
                            </span>
                        </span>
                    </div>
                    <div class="navi-item">
                        <span class="navi-link">
                            <span class="navi-text">{{ $t('calendar.title.public_holiday') }}</span>
                            <span class="mr-0">
                                <i class="fa fa-square legend-public-holiday"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

      </div>
    </div>

    <CalendarModal :event="event"></CalendarModal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import '@fullcalendar/core/vdom' 
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all';
import CalendarModal from "@/view/pages/core/calendar/CalendarModal";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import moment from 'moment';

export default {
  components: {
    FullCalendar,
    CalendarModal
  },
    data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        events: null,
        initialView: 'dayGridMonth',
        droppable: true,
        headerToolbar: {
          left: 'title',
          center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'prev,today,next'
        },
        timeZone: 'Europe/Prague',
        editable: true,
        selectable: true,
        textEscape: true,
        themeSystem: 'bootstrap4',
        height: 'auto',
        locales: allLocales,
        locale: 'cs',
        weekNumbers: true,
        weekNumberFormat: { week: 'numeric' },
        timeFormat: 'H:mm',
        selectHelper: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventDrop: this.dropAndDragEvent,
        nowIndicator: true,
        dayMaxEventRows: 3,

      },
      event: {
          id: null,
          title: null,
          address: null,
          type: null,
          start: null,
          end: null,      
          description: null,
      }, 
      type: ['meeting','call','email','holiday','other'] ,
      currentDay: new Date().getDate(),
    }
  },
  metaInfo () { 
      return { title: this.$t("calendar.meta.title")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("calendar.meta.title")}]);
    this.getEvents();
  },
  methods: {
    unsetEvent(){
      this.event = {
          id: null,
          title: null,
          address: null,
          type: null,
          start: null, 
          end: null,      
          description: null,
          allDay: false,
          eventBackground: null,
      }
    },
    setNewEvent(){
      this.unsetEvent();
      let tomorow = moment().add(1,'days').format('YYYY-MM-DD');
      this.event = {
        id: null,
        title: null,
        address: null,
        type: 'meeting',
        start: moment(tomorow + ' 09:00').format('YYYY-MM-DD HH:mm'),
        end: moment(tomorow + ' 10:00').format('YYYY-MM-DD HH:mm'),   
        description: null,
        allDay: false,
        eventBackground: null,
      }  
    },
    setEvent(event){
      this.event = {
        id: event.id,
        title : event.title,
        start : moment(event.startStr).format('YYYY-MM-DD HH:mm'),
        end : moment(event.endStr).format('YYYY-MM-DD HH:mm'),
        address : event.extendedProps.address,
        type : event.extendedProps.type,
        allDay : event.allDay,
        eventBackground : event.backgroundColor,
        description : event.extendedProps.description
      }
    },
    getEvents() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet(
        "/calendar-events?type=" + this.typeInString)
        .then((response) => {
          this.calendarOptions.events = response.data.data;
          this.loading = false;
        });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends 
    },
    handleDateSelect(selectedDate) {
      console.log(selectedDate);
      let startDate = (selectedDate.view.type == "dayGridMonth") ? moment(moment(selectedDate.startStr).format('YYYY-MM-DD') + ' 09:00').format('YYYY-MM-DD HH:mm') : moment(selectedDate.startStr).format('YYYY-MM-DD HH:mm');
      let endDate = (selectedDate.view.type == "dayGridMonth") ? moment(moment(selectedDate.endStr).subtract(1, "days").format('YYYY-MM-DD') + ' 16:00').format('YYYY-MM-DD HH:mm')  : moment(selectedDate.endStr).format('YYYY-MM-DD HH:mm');

      this.event = {
        id: null,
        title : null,
        start : startDate,
        end : endDate,
        address : null,
        type : "meeting",
        description : null,
        allDay: selectedDate.allDay
      }
      this.$bvModal.show('modal-calendar-edit');
    },
    handleEventClick(clickInfo) {
      this.$bvModal.show('modal-calendar-edit');
      this.setEvent(clickInfo.event);
    },
    dropAndDragEvent(event){
      this.updateEvent(event);
    },
    updateEvent(newEvent){
      // Loading spinner
        ApiService.apiPost("/calendar-events/" + newEvent.event.id + "/update", 
        { 
          'title': newEvent.event.title,
          'type': newEvent.event.extendedProps.type,
          'address': newEvent.event.extendedProps.address,
          'start': newEvent.event.start,
          'end': newEvent.event.end,
          'all_day': newEvent.event.allDay,
          'background': newEvent.event.backgroundColor,
          'description': newEvent.event.extendedProps.description
        })
        .then(() => {
            this.loading = false;
        }).catch(error => {
            this.loading = false;
            checkErrors(error);
       });
    },
  },
  computed: {
    // a computed getter
    typeInString: function () {
      return this.type;
    }
  }
}
</script>